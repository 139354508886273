<template>
    <div>
        <div class="feedback">
            <div class="feedback_in">
                <img src="../assets/images/ico_success2.svg" alt="">
                <span>支付密码重置成功</span>
            </div>
            <div class="feedback_in" style="display:none">
                <img src="../assets/images/ico_fail2.svg" alt="">
                <span>重置支付密码失败</span>
                <label>失败原因展示</label>
            </div>
        </div>

        <div class="bank_btn bottom">
            <button class="green" @click="handleVisible">好的</button>
        </div>
    </div>
</template>


<style scoped>
</style>

<script>
    export default {
        name: "ResetPassword",
        data() {
            return {};
        },
        methods: {
            handleVisible(option) {
                this.$emit('handleVisible', option);
            },
        }
    };
</script>
