<template>
    <div id="app">
        <div class="step1" v-show="step1Show">
            <div class="txb2">
                <div class="bank_sub_title green">
                    验证本人预留手机
                </div>

                <div class="txb2 line mgb_0_3p5vw">
                    <van-field
                            v-model="info.mobile"
                            required
                            type="tel"
                            label="预留手机"
                            maxlength="11"
                            placeholder="请输入预留手机号"
                    />
                </div>
                <div class="txb2 line mgb_0_3p5vw">
                    <div style="width:78%">
                        <van-field
                                v-model="info.code"
                                required
                                type="tel"
                                label="验证码"
                                maxlength="4"
                                placeholder="请输入验证码"
                        />
                    </div>

                    <a href="javascript:;" class="get_code" v-bind:class="info.mobile.length === 11?'':'forbidden'"
                       @click="send">{{currSecond<=0?'获取':currSecond + 's'}}</a>
                </div>

                <div class="bank_btn bottom">
                    <button @click="check"
                            v-bind:class="info.mobile.length === 11 && info.code.length === 4?'green':'forbidden'">
                        下一步
                    </button>
                    <button @click="handleVisible()" style="margin-bottom: 16px !important; background: unset; border: 1px solid #e9331e; color: #e9331e;width: 100%; height: 11vw; line-height: 11vw; background: #e9331e; border: 1px solid #e9331e; color: #fff; border-radius: 5px; margin-bottom: 16px; box-sizing: border-box; padding: 0;">
                        取消
                    </button>
                </div>

              <!-- 数字键盘 -->
              <van-number-keyboard
                  v-model="info.password"
                  :show="showKeyboard1"
                  @blur="showKeyboard1 = false"
              />

              <!-- 数字键盘 -->
              <van-number-keyboard
                  v-model="info.repassword"
                  :show="showKeyboard2"
                  @blur="showKeyboard2 = false"
              />

              <!-- 数字键盘 -->
              <van-number-keyboard
                  v-model="info.code"
                  :show="showKeyboard3"
                  @blur="showKeyboard3 = false"
              />

              <!-- 数字键盘 -->
              <van-number-keyboard
                  v-model="info.mobile"
                  :show="showKeyboard4"
                  @blur="showKeyboard4 = false"
              />
            </div>
        </div>

        <div v-show="step2Show">
            <div>
                <div
                        class="left_close iconfont "
                        @click="close_psw"
                >&#xe740;
                </div>
                <div class="bank3_title slende">设置支付密码</div>
                <div class="bank3_sub_title gray">请验证支付密码确认本人操作</div>
                <div class="yzm2 square">
                    <van-password-input
                            :value="password"
                            :gutter="10"
                            :focused="showPasswordKeyboard"
                            @focus="showPasswordKeyboard = true"
                    />
                </div>
                <div style="text-align: center; padding-top: 20px;">
                    <a-button
                            type="link"
                            @click="check2"
                            v-bind:class='{forbidden}'
                            class="confirm_psw"
                    >
                        确认
                    </a-button>
                </div>
                <van-number-keyboard
                        v-model="password"
                        :maxlength="6"
                        :show="showPasswordKeyboard"
                        @blur="showPasswordKeyboard = false"
                        @input="onInput"
                        @delete="onDelete"
                />
            </div>
        </div>

        <div v-show="step3Show">
            <div>
                <div
                        class="left_close iconfont "
                        @click="close_psw"
                >&#xe740;
                </div>
                <div class="bank3_title slende">设置支付密码</div>
                <div class="bank3_sub_title gray">请再次填写以确认</div>
                <div class="yzm2 square">
                    <van-password-input
                            :value="password2"
                            :gutter="10"
                            :focused="showPassword2Keyboard"
                            @focus="showPassword2Keyboard = true"
                    />
                </div>
                <div style="text-align: center; padding-top: 20px;">
                    <a-button
                            type="link"
                            @click="check3"
                            v-bind:class='{forbidden2}'
                            class="confirm_psw"
                    >
                        确认
                    </a-button>
                </div>
                <van-number-keyboard
                        v-model="password2"
                        :maxlength="6"
                        :show="showPassword2Keyboard"
                        @blur="showPassword2Keyboard = false"
                        @input="onInput2"
                        @delete="onDelete2"
                />
            </div>
        </div>

        <div class="loading_all" v-if="loading">
            <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
        </div>

        <van-popup v-model="showSuccess" style="width: 100%;">
            <ResetPassword @handleVisible="handleVisible"></ResetPassword>
        </van-popup>
    </div>
</template>


<style scoped>
    #app {
        background: #fff;
        height: 100vh;
        overflow: scroll;
        position: relative;
        z-index: 2000;
    }

    ::v-deep .van-uploader__upload {
        margin: 0 !important;
        width: 40vw !important;
        height: 23vw !important;
        background: url(../assets/images/photo_upload.png) center center;
        background-size: 100% 100%;
    }

    ::v-deep .bank_upload ul li:nth-child(2) .van-uploader__upload {
        margin: 0 !important;
        width: 40vw !important;
        height: 23vw !important;
        background: url(../assets/images/photo_upload2.png) center center;
        background-size: 100% 100%;
    }

    ::v-deep .van-uploader__preview {
        margin: 0 !important;
    }

    ::v-deep .van-uploader__preview-image {
        width: 40vw !important;
        height: 23vw !important;
        overflow: hidden;
    }

    ::v-deep .van-uploader__upload-icon {
        display: none;
    }

    /* ::v-deep .van-field__label {
            width: 9em !important;
        } */

    ::v-deep .van-cell__value {
        /* text-align: left !important; */
    }
</style>

<script>
    import Vue from 'vue'
    import {post} from "@/utils/http";
    import {getRealLength} from "@/utils/util";
    import Notify from "vant/es/notify";
    import {Dialog} from 'vant';
    import ResetPassword from "@/components/resetPassword";

    export default {
        name: "Verification",
        components: {
            ResetPassword
        },
        data() {
            return {
                showSuccess: false,
                step1Show: true,
                step2Show: false,
                step3Show: false,
                showKeyboard1: false,
                showKeyboard2: false,
                showKeyboard3: false,
                showKeyboard4: false,
                loading: false,
                info: {
                    mobile: "",
                    code: "",
                    password: "",
                    password2: "",
                    repassword: "",

                },
                password: "",
                password2: "",
                showPasswordKeyboard: true,
                showPassword2Keyboard: true,
                forbidden: "forbidden",
                forbidden2: "forbidden2",

                totalSecond: 60,
                currSecond: 0,
                randomStr: null
            };
        },
        methods: {
            handleVisible(option) {
                this.$router.back(-1)
            },
            send() {
                if (!this.info.mobile || this.info.mobile.length != 11) {
                    // Notify({type: "danger", message: '请输入您登记的手机号码'});
                    return;
                }

                if (this.currSecond > 0) {
                    return;
                }

                this.loading = true;
                post('/api/profile/v2/customer/send/code/' + this.info.mobile, {}).then(r => {
                    this.randomStr = r.data;
                    this.currSecond = this.totalSecond;
                    this.timing();
                    this.loading = false;
                }).catch(err => {
                    Dialog.alert({message: err.message}).then(() => {
                    });
                    this.loading = false;
                })
            },
            timing() {
                setTimeout(() => {
                    if (this.currSecond >= 0) {
                        this.currSecond = this.currSecond - 1;
                        this.timing();
                        return;
                    }
                }, 1000)
            },
            check() {
                if (!this.info.mobile || this.info.mobile.length != 11) {
                    // Notify({type: "danger", message: '请输入手机号'});
                    return;
                }

                if (!this.info.code || this.info.code.length != 4) {
                    // Notify({type: "danger", message: '请输入验证码'});
                    return;
                }

                if (!this.randomStr) {
                    Notify({type: "danger", message: '请点击获取验证码后再进行此操作'});
                    return;
                }

                this.loading = true;
                post('/api/profile/v2/customer/first/trial', {
                    "mobile": this.info.mobile,
                    "code": this.info.code,
                    "randomStr": this.randomStr
                }).then(r => {
                    this.step1Show = false
                    this.step2Show = true
                    this.loading = false;
                }).catch(err => {
                    Dialog.alert({message: err.message}).then(() => {
                    });
                    this.loading = false;
                });

            },
            check2() {
                if (this.password.length === 6) {
                    this.step2Show = false
                    this.step3Show = true
                } else {
                    Notify({type: "danger", message: '请输入6位数字密码'});
                }
            },
            check3() {
                if (this.password2.length < 6) {
                    Notify({type: "danger", message: '请输入6位数字密码'});
                } else if (this.password2 != this.password) {
                    Notify({type: "danger", message: '两次输入密码不一致'});
                } else if (this.password2 == this.password) {
                    this.loading = true;
                    post('/api/profile/v2/customer/password/reset', {
                        "newPassword": this.password,
                        "code": this.info.code,
                        "randomStr": this.randomStr
                    }).then(r => {
                        this.showSuccess = true;
                        this.loading = false;
                    }).catch(err => {
                        Dialog.alert({message: err.message}).then(() => {
                        });
                        this.loading = false;
                    })
                }
            },
            onInput(key) {
                this.password = (this.password + key).slice(0, 6);
                this.forbidden = this.password.length === 6 ? '' : 'forbidden'
                console.log('this.password', this.password)
            },
            onDelete() {
                this.password = this.password.slice(0, this.password.length - 1);
                this.forbidden = this.password.length === 6 ? '' : 'forbidden'
                console.log('this.password', this.password)
            },
            onInput2(key) {
                this.password2 = (this.password2 + key).slice(0, 6);
                this.forbidden2 = this.password2.length === 6 ? '' : 'forbidden2'
                console.log('this.password2', this.password2)
            },
            onDelete2() {
                this.password2 = this.password2.slice(0, this.password2.length - 1);
                this.forbidden2 = this.password2.length === 6 ? '' : 'forbidden2'
                console.log('this.password2', this.password2)
            },
            close_psw() {
                this.step1Show = true
                this.step2Show = false
                this.step3Show = false
                this.password = ''
                this.password2 = ''
                this.forbidden = 'forbidden'
                this.forbidden2 = 'forbidden2'
            }
        }
    };
</script>
